.s-container {
    position: relative;
}

.s-container .swiper {
    width: 70%;
    height: 12rem;
    position: unset;
}
.s-container .swiper-slide {
    display: flex;
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev {
    left: 6rem;
    color: black;
}

.s-container .swiper-button-next {
    right: 6rem;
    color: black;
}

.s-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
}

.name>:nth-child(1) {
    font-size: 1.4rem;
    font-weight: 600;
}

.name>:nth-child(2) {
    font-size: 0.7rem;
}

.s-left>:nth-child(2) {
    font-weight: bold;
    font-size: 2rem;
}

.s-left>:nth-child(3) {
    border: 1px solid var(--black);
    font-size: 0.6rem;
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
}

.imgP {
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    width: 30%;
    height: 100%;
    bottom: -20%;
}

.s-container .swiper-button-prev:hover {
    cursor: pointer;
    color: #fe956f;
}

.s-container .swiper-button-next:hover {
    cursor: pointer;
    color: #fe956f;
}

@media screen and (max-width: 856px) {
    .s-container .swiper {
        width: 90%;
        height: 10rem;
    }

    .s-container .swiper-button-prev,
    .s-container .swiper-button-next {
        display: none;
    }

}