.fWrapper {
    width: 100%;
    margin-top: 5rem;
}

.fWrapper>hr {
    width: 100%;
    height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
}

.cFooter {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.logo {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.logo>img {
    height: 2.5rem;
    width: 2.5rem;
}

.logo>span {
    font-weight: 600;
    font-size: 1rem;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}

.detail {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.7rem;
    gap: 1rem;
    font-style: italic;
}

.detail>span:nth-of-type(1) {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.line:hover {
    cursor: pointer;
    color: var(--pink);
}

.line {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon {
    width: 25px;
}

.copyright {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 0.65rem;
    margin-top: 2.5rem;
}

@media screen and (max-width: 640px) {
    .cFooter {
        justify-content: center;
    }

    .logo {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .cFooter {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1.7rem;
    gap: 2rem;
    }
}